import React, { useCallback } from 'react';

import useTracking from 'components/Globals/Analytics';
import CityDropdown from 'components/Globals/FormFields/Display/CityDropdown';
import CountryDropdown from 'components/Globals/FormFields/Display/CountryDropdown';
import OrgTypeDropdown from 'components/Globals/FormFields/Display/OrgTypeDropdown';

import { ORGANIZATION_TYPE_IDS, AGGREGATION_ON } from 'constants/index';
import { FILTER_TYPES, ENTITY_TYPES } from 'operabase-router/constants';

import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';

const OrgListingFilterDropdown = ({
  value,
  filterType,
  clearFilterTypes = [],
  onChange,
  className,
  trackingData = {},
}) => {
  const track = useTracking();
  const { obRouteContext } = useAppContext();
  const filters = obRouteContext?.linkProps?.filters;
  const { organizationType } = usePageContext();

  const onChangeHandler = useCallback(
    (e, selectedValue) => {
      const key = filterType;
      let allKeysToClear = [...clearFilterTypes, key];
      const filterUpdates = {};
      if (selectedValue) {
        filterUpdates[key] = [selectedValue];
      }

      if (filterType === FILTER_TYPES.CITY && selectedValue) {
        const countryForSelectedCity = selectedValue?.country;

        if (countryForSelectedCity) {
          const countryKey = FILTER_TYPES.COUNTRY;
          allKeysToClear.push(countryKey);

          filterUpdates[countryKey] = countryForSelectedCity ? [countryForSelectedCity] : null;
        }
      }

      if (filterType === FILTER_TYPES.CITY && !selectedValue) {
        const selectedCity = filters?.[FILTER_TYPES.CITY]?.[0]?.entity;
        const countryForSelectedCity =
          selectedCity?.country ||
          (selectedCity?.parents &&
            selectedCity.parents.find(parent => parent.entityType === ENTITY_TYPES.COUNTRY)?.entity);

        if (countryForSelectedCity) {
          const countryKey = FILTER_TYPES.COUNTRY;
          allKeysToClear = allKeysToClear.filter(filterKey => filterKey !== countryKey);
          filterUpdates[countryKey] = [
            {
              entity: countryForSelectedCity,
              entityType: ENTITY_TYPES.COUNTRY,
            },
          ];
        }
      }

      if (filterType === FILTER_TYPES.ORGANIZATION_TYPE && !selectedValue) {
        allKeysToClear = [key];
      }

      track.click({
        ...trackingData,
        meta: {
          id: selectedValue?.slug,
        },
      });

      onChange({
        filterUpdates,
        clearFilterKeys: allKeysToClear,
      });
    },
    [filterType, clearFilterTypes, track, trackingData, onChange, filters],
  );

  if (filterType === FILTER_TYPES.COUNTRY) {
    return (
      <CountryDropdown
        className={className}
        value={value}
        aggregationOn={AGGREGATION_ON.ORGANIZATIONS}
        aggregationFilters={{
          organization_type_id: organizationType?.id,
          ...(organizationType?.id !== ORGANIZATION_TYPE_IDS.COMPETITION && { has_productions: 'yes' }),
        }}
        onChange={onChangeHandler}
        hideLabel
      />
    );
  }

  if (filterType === FILTER_TYPES.CITY) {
    const selectedCountry = filters?.[FILTER_TYPES.COUNTRY]?.[0]?.entity;

    return (
      <CityDropdown
        className={className}
        value={value}
        aggregationOn={AGGREGATION_ON.ORGANIZATIONS}
        aggregationFilters={{
          organization_type_id: organizationType?.id,
          country_id: selectedCountry?.id,
          ...(organizationType?.id !== ORGANIZATION_TYPE_IDS.COMPETITION && { has_productions: 'yes' }),
        }}
        onChange={onChangeHandler}
        hideLabel
      />
    );
  }

  if (filterType === FILTER_TYPES.ORGANIZATION_TYPE) {
    return <OrgTypeDropdown className={className} value={value} onChange={onChangeHandler} />;
  }

  return null;
};

export default OrgListingFilterDropdown;
