import React, { useCallback, useMemo } from 'react';
import omit from 'lodash/omit';
import classnames from 'classnames';

import { SECTIONS, COMPONENTS, SUB_COMPONENTS, CATEGORIES } from 'components/Globals/Analytics/constants';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';

import { TP, SEO_EXCLUDED_ORGANIZATION_TYPE_IDS } from 'constants/index';
import { FILTER_TYPES, ENTITY_TYPES } from 'operabase-router/constants';

import usePageContext from 'utils/hooks/usePageContext';
import { useAppliedCountryFilter, useAppliedCityFilter } from 'utils/hooks/useLocationFilter';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import useAppContext from 'utils/hooks/useAppContext';
import { useQuery } from 'utils/react-query';

import { useTranslation } from 'src/i18n';
import orgQueries from 'containers/Organizations/queries';

import OrgListingFilterDropdown from '../OrgListingFilterDropdown';
import classes from './IndexPageFilters.module.scss';

const trackingData = {
  section: SECTIONS.ORGANIZATION_LISTING,
  component: COMPONENTS.FILTERS,
  category: CATEGORIES.SEARCH,
};

export const useOrgIndexPageFiltersMeta = () => {
  const { obRouteContext } = useAppContext();
  const { filters } = obRouteContext?.linkProps;
  const { orgTypeSlug, organizationType, hasAppliedFilters } = usePageContext();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const noIndexPage = !!filters?.query;
  const showTabularList = filters?.page > 0 || !!filters?.letter || !!filters?.query;

  // NOTE: need to check with product team do we need country dropdown or not
  const cityFilter = filters?.[FILTER_TYPES.CITY]?.[0];
  const countryFilter = filters?.[FILTER_TYPES.COUNTRY]?.[0];

  const appliedCityFilter = cityFilter?.entity;
  const appliedCountryFilter =
    countryFilter?.entity || cityFilter?.parents?.find(({ entityType }) => entityType === ENTITY_TYPES.COUNTRY)?.entity;

  const { data: selectedCountry } = useAppliedCountryFilter(appliedCountryFilter);
  const { data: selectedCity } = useAppliedCityFilter(appliedCityFilter);

  const hasAppliedCountryFilter = !!selectedCountry || false;
  const hasAppliedCityFilter = !!selectedCity || false;
  const hasAppliedOrgTypeFilter = !!organizationType || false;

  const title = useMemo(() => {
    if (hasAppliedCountryFilter || hasAppliedCityFilter) {
      let location = hasAppliedCountryFilter ? selectedCountry?.name : '';

      if (hasAppliedCityFilter) {
        if (location) {
          location += ', ';
        }

        location += selectedCity?.name;
      }

      return t(`${TP}.FN_ORG_TYPE_IN_LOCATION`, {
        orgType: organizationType?.name || t(`${TP}.FN_ORGANIZATIONS`),
        location,
      });
    }

    if (hasAppliedOrgTypeFilter) {
      return organizationType?.name;
    }

    return t(`${TP}.FN_ORGANIZATIONS`);
  }, [
    t,
    organizationType,
    selectedCountry,
    selectedCity,
    hasAppliedCountryFilter,
    hasAppliedCityFilter,
    hasAppliedOrgTypeFilter,
  ]);

  const shouldFetchTrendingList = useMemo(() => {
    if (!hasAppliedFilters) {
      return false;
    }

    const isValidOrgTypeSelection =
      !orgTypeSlug || (orgTypeSlug === organizationType?.slug && organizationType?.pro >= 8);

    if (hasAppliedCityFilter) {
      return isValidOrgTypeSelection && selectedCity?.pro > 0;
    }

    if (hasAppliedCountryFilter) {
      return isValidOrgTypeSelection && selectedCountry?.pro > 0;
    }

    return isValidOrgTypeSelection;
  }, [
    hasAppliedFilters,
    hasAppliedCityFilter,
    hasAppliedCountryFilter,
    orgTypeSlug,
    organizationType?.pro,
    organizationType?.slug,
    selectedCity?.pro,
    selectedCountry?.pro,
  ]);

  const trendingCount = useMemo(() => {
    if (shouldFetchTrendingList) {
      if (hasAppliedCityFilter) {
        return selectedCity?.total;
      }

      if (hasAppliedCountryFilter) {
        return selectedCountry?.total;
      }

      return organizationType?.total;
    }

    return null;
  }, [
    hasAppliedCityFilter,
    hasAppliedCountryFilter,
    organizationType?.total,
    selectedCity?.total,
    selectedCountry?.total,
    shouldFetchTrendingList,
  ]);

  return {
    noIndexPage,
    title,
    selectedOrgType: organizationType,
    selectedCountry,
    selectedCity,
    hasAppliedOrgTypeFilter,
    hasAppliedCountryFilter,
    hasAppliedCityFilter,
    hasAppliedFilters,
    showTabularList,
    shouldFetchTrendingList,
    trendingCount,
  };
};

const IndexPageFilters = ({ sticky }) => {
  const { obRouteContext } = useAppContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const filters = obRouteContext?.linkProps?.filters;
  const organizationType = filters?.[FILTER_TYPES.ORGANIZATION_TYPE]?.[0]?.entity;
  const { applyFilters } = usePageContext();
  const { selectedCountry, selectedCity } = useOrgIndexPageFiltersMeta();

  /* NOTE: Query to fetch organization types for horizontal scroll list */
  const { data: organizationTypes } = useQuery(
    orgQueries.getOrganizationTypes({
      queryConfig: {
        select: response =>
          response?.data?.slice(0, 10)?.filter(({ id }) => !SEO_EXCLUDED_ORGANIZATION_TYPE_IDS.includes(id)),
      },
    }),
  );

  const onChangeFilter = useCallback(
    ({ filterUpdates, clearFilterKeys = [] }) => {
      const pageFilterKey = FILTER_TYPES.PAGE;
      const pageFilterCurrentValue = filters?.[pageFilterKey];
      const finalFiltersExcludingPage = {
        ...omit(filters, [...clearFilterKeys, pageFilterKey]),
        ...filterUpdates,
      };

      applyFilters({
        entityType: ENTITY_TYPES.ORGANIZATION,
        filters: {
          ...finalFiltersExcludingPage,
          ...(pageFilterCurrentValue && Object.keys(finalFiltersExcludingPage).length > 0
            ? { [pageFilterKey]: 1 }
            : {}),
        },
      });
    },
    [filters, applyFilters],
  );

  /* NOTE: EntityProfileSelector was removed as per requirements */

  const Wrapper = sticky ? HorizontalScroller : 'div';
  const OrgTypeListWrapper = sticky && !isDesktop ? 'div' : HorizontalScroller;

  return (
    <Wrapper outset>
      <div className={classnames(classes.root, { [classes.root__sticky]: sticky })}>
        <div className={classnames(classes.root_orgTypes, { [classes.root_orgTypesSticky]: sticky })}>
          <OrgListingFilterDropdown
            className={classnames(classes.dropdown, classes.dropdown_orgType)}
            filterType={FILTER_TYPES.ORGANIZATION_TYPE}
            value={organizationType}
            onChange={onChangeFilter}
            trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.ORGANIZATION_TYPE }}
          />
          <OrgListingFilterDropdown
            filterType={FILTER_TYPES.COUNTRY}
            value={selectedCountry}
            onChange={onChangeFilter}
            clearFilterTypes={[FILTER_TYPES.CITY]}
            className={classnames(classes.dropdown, classes.dropdown_countryDropdown)}
            trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.COUNTRY }}
          />
          <OrgListingFilterDropdown
            filterType={FILTER_TYPES.CITY}
            value={selectedCity}
            onChange={onChangeFilter}
            className={classnames(classes.dropdown, classes.dropdown_cityDropdown)}
            trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.CITY }}
          />
        </div>
        {organizationTypes?.length > 0 && (
          <div className={classnames(classes.chipContainer, { [classes.chipContainer__sticky]: sticky })}>
            <div className={classes.separator}>|</div>
            <OrgTypeListWrapper outset>
              <div className={classes.scrollableContent}>
                {organizationTypes.map((item, index) => (
                  <LinkButton
                    variant="text"
                    key={`chip-${item.id || index}`}
                    styles={{
                      root: classnames(classes.chipItem, {
                        [classes.chipItem__selected]: organizationType?.id === item.id,
                      }),
                    }}
                    onClick={() =>
                      onChangeFilter({
                        filterUpdates: { [FILTER_TYPES.ORGANIZATION_TYPE]: [item] },
                        clearFilterKeys: [FILTER_TYPES.ORGANIZATION_TYPE],
                      })
                    }
                    preventDefault
                    disableUnderline
                    aria-label={`Filter by ${item.name}`}
                  >
                    <Typography variant="h2" size="11" className={classes.chipText}>
                      {item.name}
                    </Typography>
                  </LinkButton>
                ))}
              </div>
            </OrgTypeListWrapper>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default IndexPageFilters;
