import React, { useCallback } from 'react';

import AutoCompleteDropdown from 'components/uiLibrary/FormInputs/BaseComponents/AutoCompleteDropdown';

import orgQueries from 'containers/Organizations/queries';

import { useAppliedOrgTypeFilter } from 'utils/globals/ssrQueries/hooks';

import { TP, SEO_EXCLUDED_ORGANIZATION_TYPE_IDS } from 'constants/index';

import { useTranslation } from 'src/i18n';

const OrgTypeDropdown = ({ className, value, onChange }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  const organizationType = useAppliedOrgTypeFilter(value);

  const query = useCallback(
    props =>
      orgQueries.getOrganizationTypes({
        ...props,
        queryConfig: {
          ...props.queryConfig,
          select: response => response?.data?.filter(({ id }) => !SEO_EXCLUDED_ORGANIZATION_TYPE_IDS.includes(id)),
        },
      }),
    [],
  );

  return (
    <AutoCompleteDropdown
      className={className}
      value={organizationType}
      onChange={onChange}
      searchQuery={query}
      disableSearchOnInputChange
      textFieldProps={{
        ...(!organizationType && {
          placeholder: t(`${TP}.FN_ORG_TYPE`),
        }),
      }}
    />
  );
};

export default OrgTypeDropdown;
